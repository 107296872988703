import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../context";
import Swal from "sweetalert2";

function PressKit() {
  const [files, setFiles] = useState([]);
  const { base_url, isLoggedIn, setIsLoggedIn, setUser, user } =
    useContext(AuthContext);
  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      const response = await axios.post(`${base_url}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire({
        text: "Successfully Uploaded",
        icon: "success",
      });
      setFiles([]);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Error while uploading content. Try again later.",
        icon: "error",
      });
    }
  };

  return (
    <div className="my-4 me-3">
      <div
        className="p-4 px-5"
        style={{ borderRadius: "10px", border: "1px solid #f0ece4" }}
      >
        <h3>Press Kit Content</h3>
        <div className="row">
          <div className="col form-group">
            <input
              className="form-control"
              type="file"
              multiple
              onChange={handleFileChange}
            />
          </div>
          <button className="col-auto btn btn-success" onClick={handleUpload}>
            Upload Files
          </button>
        </div>
      </div>
    </div>
  );
}
export default PressKit;
