import React from "react";

function Personal() {
  return (
    <div className="form-box" id="travel">
      {/* Personal Info section */}

      <div className="row px-4 py-2">
        {/* First Form */}
        <div className="col-12 p-1 py-5">
          <h5>Personal Details</h5>
          <div className="d-flex gap-2">
            <div className="form-group w-100">
              <label className="form-label">Title</label>
              <select className="form-select" type="text" name="first_name">
                <option value={"Mr"}>Mr</option>
                <option value={"Ms"}>Ms</option>
                <option value={"Mrs"}>Mrs</option>
              </select>
            </div>
          </div>
          {/* Name Fields */}
          <div className="d-flex gap-2">
            <div className="form-group">
              <label className="form-label">First Name</label>
              <input className="form-control" type="text" name="first_name" />
            </div>
            <div className="form-group">
              <label className="form-label">Middle Name</label>
              <input className="form-control" type="text" name="middle_name" />
            </div>
            <div className="form-group">
              <label className="form-label">Last Name</label>
              <input className="form-control" type="text" name="last_name" />
            </div>
          </div>
          <div className="d-flex gap-2">
            <div className="form-group w-100">
              <label className="form-label">Date of Birth</label>
              <input
                className="form-control"
                type="date"
                name="date_of_birth"
              />
            </div>
          </div>
          <div className="d-flex gap-2">
            <div className="form-group">
              <label className="form-label">Nationality</label>
              <input className="form-control" type="text" name="nationality" />
            </div>
            <div className="form-group">
              <label className="form-label">Phone</label>
              <input className="form-control" type="text" name="phone" />
            </div>
            <div className="form-group">
              <label className="form-label">Email</label>
              <input className="form-control" type="email" name="email" />
            </div>
          </div>
        </div>

        {/* First Form till here */}
      </div>
      {/* Company section */}
      <div className="row px-4 py-2">
        {/* First Form */}
        <div className="col-12 p-1 pt-0 pb-5">
          <h5>Company Details</h5>
          <div className="d-flex gap-2">
            <div className="form-group w-100">
              <label className="form-label">Title</label>
              <select className="form-select" type="text" name="first_name">
                <option value={"Mr"}>Mr</option>
                <option value={"Ms"}>Ms</option>
                <option value={"Mrs"}>Mrs</option>
              </select>
            </div>
          </div>
          {/* Name Fields */}
          <div className="d-flex gap-2">
            <div className="form-group">
              <label className="form-label">First Name</label>
              <input className="form-control" type="text" name="first_name" />
            </div>
            <div className="form-group">
              <label className="form-label">Middle Name</label>
              <input className="form-control" type="text" name="middle_name" />
            </div>
            <div className="form-group">
              <label className="form-label">Last Name</label>
              <input className="form-control" type="text" name="last_name" />
            </div>
          </div>
          <div className="d-flex gap-2">
            <div className="form-group w-100">
              <label className="form-label">Date of Birth</label>
              <input
                className="form-control"
                type="date"
                name="date_of_birth"
              />
            </div>
          </div>
          <div className="d-flex gap-2">
            <div className="form-group">
              <label className="form-label">Nationality</label>
              <input className="form-control" type="text" name="nationality" />
            </div>
            <div className="form-group">
              <label className="form-label">Phone</label>
              <input className="form-control" type="text" name="phone" />
            </div>
            <div className="form-group">
              <label className="form-label">Email</label>
              <input className="form-control" type="email" name="email" />
            </div>
          </div>
        </div>

        {/* First Form till here */}
      </div>
      {/* Save and Continue button */}
      <div className="row px-4 pb-5">
        <div className="col-12 p-1 d-flex justify-content-center">
          <button className="btn btn-danger">Save & Continue</button>
        </div>
      </div>
    </div>
  );
}

export default Personal;
