import React from "react";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context";
import { redirect } from "react-router-dom";
import Participant from "./Participant";

function Attendies() {
  const [tableRows, setTableRows] = useState([]);
  const [state, setState] = useState({
    searchtext: "",
  });
  const [prev_num, setprev_num] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [has_prev, sethas_prev] = useState("");
  const [next_num, setnext_num] = useState("");
  const [has_next, sethas_next] = useState("");
  const [pagination_page, setpagination_page] = useState("");
  const [paginat, setpaginat] = useState([]);

  const { base_url, isLoggedIn, setIsLoggedIn, setUser, user } =
    useContext(AuthContext);
  //   console.log(user);
  useEffect(() => {
    const token_verification = async () => {
      let token = sessionStorage.getItem("token"); // get token
      if (!token || token === undefined || token === "") {
        setUser("");
        setIsLoggedIn(false);
      } else {
        // else send a request to the route and validate whether the token is valid or not
        let result = await fetch(`${base_url}/user-info`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).catch((err) => {
          //   console.log(err);
        });

        if (result && result.ok) {
          let data = await result.json();
          setIsLoggedIn(true);
          setUser(data);
          //   page_number = page;
          //   console.log(window.location.search);
          const urlParams = new URLSearchParams(window.location.search);
          const myParam = urlParams.get("page");
          let data_table = await fetch(`${base_url}/entries?page=${myParam}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).catch((err) => {
            // console.log(err);
          });
          if (data_table && data_table.ok) {
            let table_records = await data_table.json();
            setTableRows(table_records.data);
            sethas_next(table_records.has_next);
            sethas_prev(table_records.has_prev);
            setpaginat(table_records.paginat);
            setpagination_page(table_records.pagination_page);
            // console.log(table_records.pagination_page);
            setprev_num(table_records.prev_num);
            setnext_num(table_records.next_num);
            // console.log(table_records.data);
          }
        } else {
          sessionStorage.clear();
          setIsLoggedIn(false);
          setUser("");
        }
      }
    };
    token_verification();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]); // for verifying the token and protect login

  const update = (event) => {
    const target = event.currentTarget;

    setState({
      ...state,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    });
  };

  // const handleDownload = async (event, id) => {
  //   event.preventDefault();
  //   window.open(`${base_url}/download/${id}`);
  // };
  // const handleSearch = async (event) => {
  //   event.preventDefault();
  //   let token = sessionStorage.getItem("token");
  //   if (!token || token === undefined || token === "") {
  //     sessionStorage.clear();
  //     setIsLoggedIn(false);

  //     setUser("");
  //   } else {
  //     try {
  //       let data_table = await fetch(
  //         `${base_url}/entries?text=${state.searchtext}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       ).catch((err) => {
  //         // console.log(err);
  //       });
  //       if (data_table && data_table.ok) {
  //         let table_records = await data_table.json();
  //         setTableRows(table_records.data);
  //         sethas_next(table_records.has_next);
  //         sethas_prev(table_records.has_prev);
  //         setpaginat(table_records.paginat);
  //         setpagination_page(table_records.pagination_page);
  //         //   console.log(table_records.pagination_page);
  //         setprev_num(table_records.prev_num);
  //         setnext_num(table_records.next_num);
  //         // console.log(table_records.data);
  //       }
  //     } catch (err) {
  //       // console.log(err.status);
  //     }
  //   }
  // };

  const addParticipant = () => {
    setShowPopUp(true);
  };
  return (
    <div id="page-content-wrapper">
      {showPopUp ? (
        <Participant showPopUp={showPopUp} setShowPopUp={setShowPopUp} />
      ) : (
        ""
      )}
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <div className="fs-2">Total Attendees</div>
            <div className="btn-top">
              <button className="btn edit me-2 px-5">
                Edit
                <span className="ps-3">
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </span>
              </button>
              <button className="btn download  me-2" onClick={addParticipant}>
                Add Participant
                <span className="ps-3">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
              </button>
              <button className="btn addpart">
                Download
                <span className="ps-3">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4">
                <div
                  className="form-group d-flex border border-1 border-black"
                  style={{ borderRadius: "25px" }}
                >
                  <input type="text" className="form-control border-0" />

                  <button
                    className="btn mx-1"
                    style={{ backgroundColor: "white", borderRadius: "25px" }}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group d-flex">
                  <label className="form-label me-2 pt-1">Show:</label>
                  <select className="form-control form-select">
                    <option value={"all"} selected>
                      All
                    </option>

                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div className="col-2 p-0">
                <div className="form-group d-flex">
                  <label
                    className="form-label me-2 pt-1"
                    style={{ textWrap: "nowrap" }}
                  >
                    Sort by:
                  </label>
                  <select className="form-control form-select">
                    <option value={"all"} selected>
                      All
                    </option>

                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            </div>
            <div id="table-scroll" className="table-scroll">
              <div className="table-wrap">
                <table className="main-table">
                  <thead>
                    <tr className="tableheader">
                      <th
                        className="fixed-side"
                        style={{
                          backgroundColor: "#E6E1D6",
                        }}
                        scope="col"
                      >
                        Status
                      </th>
                      <th
                        className="fixed-side "
                        style={{ backgroundColor: "#E6E1D6" }}
                        scope="col"
                      >
                        Updated by
                      </th>
                      <th
                        className="fixed-side "
                        style={{ backgroundColor: "#E6E1D6" }}
                        scope="col"
                      >
                        Participate Code
                      </th>
                      <th scope="col">Vip</th>
                      <th scope="col">Sub</th>
                      <th scope="col">Relation to LG</th>
                      <th scope="col">Current Country</th>
                      <th scope="col">Current City</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Gender</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Middle Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Passport Number</th>
                      <th scope="col">Nationality</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Issue Date</th>
                      <th scope="col">Date of Birth</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">E-mail</th>
                      <th scope="col">Visa Requirement</th>
                      <th scope="col">Invitation Letter</th>
                      <th scope="col">Invitation Letter(soft or hard copy)</th>
                      <th scope="col">Address Send</th>
                      <th scope="col">Excursion</th>
                      <th scope="col">Excursion Option</th>
                      <th scope="col">Meal Type</th>
                      <th scope="col">Food Allergy</th>
                      <th scope="col">Allergies</th>
                      <th scope="col">Check - In Date</th>
                      <th scope="col">Check - Out Date</th>
                      <th scope="col">Early Check - In</th>
                      <th scope="col">Early Check - Out</th>
                      <th scope="col">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableRows.map((rec, index) => {
                      return (
                        <tr key={index}>
                          <th className="fixed-side py-2">
                            <div
                              className="form-group d-flex border-1"
                              style={{
                                borderRadius: "25px",
                                border: "1px solid red",
                              }}
                            >
                              <span
                                className="py-2 mx-2"
                                style={{ fontSize: "10px" }}
                              >
                                <i
                                  class="fa fa-circle fa-1x text-danger"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <select
                                className="form-control bg-transparent border-0 p-0"
                                style={{
                                  textWrap: "nowrap",
                                  width: "100px",
                                  outline: "none !important",
                                }}
                              >
                                <option selected>Incomplete</option>
                                <option>Proceed</option>
                                <option>Confirmed</option>
                              </select>
                            </div>
                          </th>
                          <td className="fixed-side  py-2">
                            {rec.updating_date}
                          </td>
                          <td className="fixed-side  py-2">{rec.id}</td>
                          <td className="d-flex justify-content-center align-items-center">
                            <i className="fa fa-star fa-1x text-warning"></i>
                          </td>
                          <th scope="row">
                            {(pagination_page - 1) * 10 + (index + 1)}
                          </th>
                          <td>{rec.relation_to_lg}</td>
                          <td>{rec.country}</td>

                          <td style={{ textTransform: "capitalize" }}>
                            {rec.current_city}
                          </td>
                          <td>{rec.designation}</td>
                          <td>{rec.title}</td>
                          <td>{rec.first_name}</td>
                          <td>{rec.middle_name}</td>
                          <td>{rec.last_name}</td>
                          <td>{rec.passport_number}</td>
                          <td>{rec.country}</td>
                          <td>{rec.expiry_date}</td>
                          <td>{rec.issue_date}</td>
                          <td>{rec.date_of_birth}</td>
                          <td>{rec.phone}</td>
                          <td>{rec.email}</td>
                          <td>{rec.visa_requirements}</td>
                          <td>
                            {rec.invitation_letter == "not required"
                              ? "no"
                              : "yes"}
                          </td>
                          <td>{rec.invitation_letter}</td>
                          <td>{rec.address}</td>
                          <td>{rec.excursion}</td>
                          <td>{rec.excursion_options}</td>
                          <td>{rec.meal_type}</td>
                          <td>{rec.meal_food_allergies}</td>
                          <td>{rec.meal_specify_allergies}</td>
                          <td>{rec.hotel_check_in_date}</td>
                          <td>{rec.hotel_check_out_date}</td>
                          <td>{rec.hotel_early_check_in}</td>
                          <td>{rec.hotel_late_check_out}</td>
                          <td>{rec.remarks}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attendies;
