import { AuthContext } from "../context";
import React, { useState, useContext, useEffect } from "react";

function TopBar() {
  const { base_url, isLoggedIn, setIsLoggedIn, setUser, user } =
    useContext(AuthContext);
  // useEffect(() => {
  //   const token_verification = async () => {
  //     let token = sessionStorage.getItem("token"); // get token
  //     if (!token || token === undefined || token === "") {
  //       // check if the token is present or not if no then dont do anything
  //       setIsLoggedIn(false);
  //     } else {
  //       // else send a request to the route and validate whether the token is valid or not
  //       let result = await fetch(`${base_url}/user-info`, {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }).catch((err) => {
  //         console.log(err);
  //       });

  //       if (result && result.ok) {
  //         let data = await result.json();
  //         setIsLoggedIn(true);
  //         console.log(data);
  //         setUser(data);
  //       } else {
  //         sessionStorage.clear();
  //         setIsLoggedIn(false);
  //         setUser("");
  //       }
  //     }
  //   };
  //   token_verification();
  // });
  const handleLogout = async (event) => {
    event.preventDefault();

    let token = sessionStorage.getItem("token");
    if (!token || token === undefined || token === "") {
      sessionStorage.clear();
      setIsLoggedIn(false);
      setUser("");
    } else {
      await fetch(`${base_url}/logout`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          sessionStorage.clear();
          setIsLoggedIn(false);
          setUser("");
        })
        .catch((err) => console.log(err));
    }
  };
  console.log(user);
  if (isLoggedIn === true && isLoggedIn !== "") {
    return (
      <nav
        className="sidebarbg d-flex justify-content-end"
        style={{ padding: "12px" }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "fit-content",
            borderRadius: "25px",
            padding: "5px",
          }}
        >
          <span className="p-2">
            Morning! <b>{user ? user.user.name : ""}</b>
          </span>
          <button
            className="btn ms-5 me-4"
            style={{ borderRadius: "25px", border: "1px solid red" }}
          >
            Edit Profile
          </button>
          <button
            className="btn bg-light text-danger"
            style={{ borderRadius: "25px" }}
            onClick={handleLogout}
          >
            <i class="fa fa-power-off" aria-hidden="true"></i>
          </button>
        </div>
      </nav>
    );
  }
}

export default TopBar;
