import React, { useState, useContext, useEffect } from "react";
import LG_logo from "./assets/assets/lg-logo.png";
import { Link } from "react-router-dom";
import { AuthContext } from "./context";

function Sidebar() {
  const { base_url, isLoggedIn, setIsLoggedIn, setUser, user } =
    useContext(AuthContext);

  if (isLoggedIn === true && isLoggedIn !== "") {
    return (
      <div className="border-end " id="sidebar-wrapper">
        <div className="sidebar-heading border-bottom sidebarbg ">
          <img src={LG_logo} alt="logo" />
        </div>
        <div className="list-group list-group-flush sidebarbox">
          <p className="custom-color">Statistic</p>
          {/* <Link className="list-group-item list-group-item-action  pad" to="/">
            Performance Overview
          </Link> */}
          <Link
            className="list-group-item list-group-item-action pad"
            to="/total_attendee"
          >
            Total Attendees
          </Link>
          <Link
            className="list-group-item list-group-item-action pad"
            to="/press_kit"
          >
            Upload Press Kit
          </Link>
          <Link
            className="list-group-item list-group-item-action pad"
            to="/view_press_kit"
          >
            View Press Kit
          </Link>
          {/* <a className="list-group-item list-group-item-action  pad" href="#!">
            Submissions
          </a> */}
          {/* <!-- <a className="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Events</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Profile</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Status</a> --> */}
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"></div>
          <p className="custom-color">Content</p>
          <a className="list-group-item list-group-item-action  pad" href="#!">
            Event Inforamation{" "}
          </a>
          <a className="list-group-item list-group-item-action pad" href="#!">
            Timeline
          </a>
          <a className="list-group-item list-group-item-action  pad" href="#!">
            Venue
          </a>
          <a className="list-group-item list-group-item-action pad" href="#!">
            Events
          </a>
          <a className="list-group-item list-group-item-action pad" href="#!">
            Profile
          </a>
        </div>
      </div>
    );
  }
}

export default Sidebar;
