import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../context";

function ViewPressKit() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const { base_url, isLoggedIn, setIsLoggedIn, setUser, user } =
    useContext(AuthContext);
  useEffect(() => {
    const fetchUploadedFiles = async () => {
      try {
        const response = await axios.get(`${base_url}/uploaded_files`);
        setUploadedFiles(response.data);
      } catch (error) {
        console.error("Error fetching uploaded files:", error);
      }
    };

    fetchUploadedFiles();
  }, []);

  const handleDeleteFile = async () => {
    try {
      await axios.post(`${base_url}/delete_file`, {
        filename: selectedFile,
      });
      // Update the list of uploaded files after deletion
      const response = await axios.get(`${base_url}/uploaded_files`);
      setUploadedFiles(response.data);
      setSelectedFile(""); // Clear selected file
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  return (
    <div className="my-4 me-3">
      <div
        className="p-4 px-5"
        style={{ borderRadius: "10px", border: "1px solid #f0ece4" }}
      >
        <h3>Press Kit Content</h3>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr className="text-center">
              <th>S.No</th>
              <th>File Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {uploadedFiles.map((filename, index) => (
              <tr key={index} className="text-center">
                <td>{index + 1}</td>
                <td>{filename}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => setSelectedFile(filename)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedFile && (
        <div>
          <p>Selected file: {selectedFile}</p>
          <button className="btn btn-danger" onClick={handleDeleteFile}>
            Delete Now
          </button>
        </div>
      )}
    </div>
  );
}

export default ViewPressKit;
