import "./App.css";
import Sidebar from "./Sidebar";
import "./assets/css/styles.css";
import Attendies from "./components/Attendies";
import PressKit from "./components/PressKit";
import React, { useState, useEffect } from "react";
import Dashboard from "./components/Dashboard";
import TopBar from "./components/TopBar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthContext } from "./context";
import Login from "./components/Login";
import Participant from "./components/Participant";
import ViewPressKit from "./components/ViewPressKit";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState("");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState("");
  useEffect(() => {
    const token_verification = async () => {
      let token = sessionStorage.getItem("token"); // get token
      if (!token || token === undefined || token === "") {
        // check if the token is present or not if no then dont do anything
        setIsLoggedIn(false);
        setUser("");
        setLoading(false);
      } else {
        // else send a request to the route and validate whether the token is valid or not
        let result = await fetch(`${base_url}/user-info`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).catch((err) => {
          console.log(err);
        });

        if (result && result.ok) {
          let data = await result.json();
          setIsLoggedIn(true);
          setUser(data);
          setLoading(false);
        } else {
          sessionStorage.clear();
          setIsLoggedIn(false);
          setUser("");
          setLoading(false);
        }
      }
    };
    token_verification();

    // Send the token in the
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // for verifying the token and protect login

  const base_url = process.env.REACT_APP_API_URI;
  if (loading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{ base_url, isLoggedIn, setIsLoggedIn, user, setUser }}
    >
      <Router>
        <div className="d-flex" id="wrapper">
          {isLoggedIn && isLoggedIn !== "" ? <Sidebar /> : ""}
          <div id="page-content-wrapper">
            {isLoggedIn && isLoggedIn !== "" ? <TopBar /> : ""}
            <Routes>
              <Route
                exact
                path="/"
                element={
                  isLoggedIn && isLoggedIn !== "" ? (
                    // <Dashboard />
                    <Attendies />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/press_kit"
                element={
                  isLoggedIn && isLoggedIn !== "" ? (
                    <PressKit />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/view_press_kit"
                element={
                  isLoggedIn && isLoggedIn !== "" ? (
                    <ViewPressKit />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/total_attendee"
                element={
                  isLoggedIn && isLoggedIn !== "" ? (
                    <Attendies />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/login"
                element={
                  isLoggedIn && isLoggedIn !== "" ? (
                    <Navigate to="/" />
                  ) : (
                    <Login />
                  )
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
