import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context";

import Rectangle111 from "./assets/images/Rectangle111.svg";
import Maskgroup from "./assets/images/Maskgroup.svg";

const Login = () => {
  const { base_url, setIsLoggedIn, setUser } = useContext(AuthContext);
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    const token_verification = async () => {
      let token = sessionStorage.getItem("token"); // get token
      if (!token || token === undefined || token === "") {
        // check if the token is present or not if no then dont do anything
      } else {
        // else send a request to the route and validate whether the token is valid or not
        let result = await fetch(`${base_url}/user-info`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).catch((err) => {
          console.log(err);
        });

        if (result && result.ok) {
          let data = await result.json();
          setIsLoggedIn(true);
          setUser(data);
        } else {
          sessionStorage.clear();
          setIsLoggedIn(false);
          setUser("");
        }
      }
    };
    token_verification();

    // Send the token in the
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // for verifying the token and protect login

  const update = (event) => {
    const target = event.currentTarget;

    setState({
      ...state,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    });
  };
  const handleLogin = async (event) => {
    event.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.append("email", state.email);
    bodyFormData.append("password", state.password);
    try {
      let res = await fetch(`${base_url}/signin`, {
        method: "POST",
        body: bodyFormData,
      }).catch((err) => {
        console.log(err);
      });
      // We will get  the access token cookie and refresh token cookie and it will be stored in react page

      const response = await res.json();

      if (!res.ok) {
        console.log(response.msg);
        // failed to logged in show in form
      } else {
        sessionStorage.setItem("token", response.token);
        setIsLoggedIn(true);
        let result = await fetch(`${base_url}/user-info`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${response.token}`,
          },
        }).catch((err) => {
          console.log(err);
        });

        if (result && result.ok) {
          let data = await result.json();
          setIsLoggedIn(true);
          setUser(data);
        } else {
          sessionStorage.clear();
          setIsLoggedIn(false);
          setUser("");
        }
        // Redirect to dashboard
        //
        console.log(response.msg);
      }
    } catch (err) {
      // console.log(err.status);
    }
  };

  return (
    //   <div
    //     className="container mx-auto h-100 custom-width"
    //     style={{ paddingBottom: "150px", maxWidth: "650px" }}
    //   >
    //     <div>
    //       <div
    //         className="row d-flex align-items-center"
    //         style={{ color: "#494c4f" }}
    //       >
    //         <div className="col-12 text-center mt-5">
    //           <h3 className="p-3">
    //             <span className="" style={{ color: "#494c4f" }}>
    //               LG SHOWCASE 2024
    //             </span>
    //           </h3>
    //         </div>
    //       </div>
    //       <div className="d-flex justify-content-center">
    //         <form className="row text-dark body-form-custom rounded">
    //           {/* {% with messages = get_flashed_messages(with_categories=true) %} {% if messages %} {% for category, message in messages %}
    // <div className="col-12 alert alert-{{category}}" role="alert">
    //   {{ message }}
    // </div>
    // {% endfor %} {% endif %} {% endwith %} */}
    //           <div className="col-12 mb-3">
    //             <label htmlhtmlFor="email" className="form-label">
    //               Email ID <span className="text-danger">*</span>
    //             </label>
    //             <input
    //               type="email"
    //               onChange={update}
    //               className="form-control"
    //               name="email"
    //               id="email"
    //               aria-describedby="emailHelp"
    //               required
    //             />
    //           </div>
    //           <div className="col-12 mb-4">
    //             <label htmlhtmlFor="password" className="form-label">
    //               Password <span className="text-danger">*</span>
    //             </label>
    //             <input
    //               type="password"
    //               onChange={update}
    //               id="password"
    //               name="password"
    //               required
    //               className="form-control"
    //             />
    //           </div>

    //           <div className="row m-0">
    //             <button
    //               className="col-12 btn btn-danger w-100"
    //               onClick={handleLogin}
    //             >
    //               Login
    //             </button>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>

    <div>
      <section
        className="skrollable u-clearfix u-image u-shading u-section-1"
        id="sec-a68f"
        data-image-width="1032"
        data-image-height="1459"
      >
        <div className="u-container-style u-expanded-width u-group u-opacity u-opacity-50 u-shape-rectangle u-group-1">
          <div className="u-container-layout u-container-layout-1">
            <div className="u-container-style u-group u-hover-feature u-preserve-proportions u-radius u-shape-round u-white u-group-2">
              <div className="u-container-layout u-container-layout-2">
                <div className="custom-expanded u-align-center u-border-1 u-border-grey-5 u-line u-line-horizontal u-line-1"></div>
                <div className="custom-expanded u-align-center u-border-1 u-border-grey-5 u-line u-line-horizontal u-line-2"></div>
                <img
                  className="u-expanded-width u-image u-image-round u-radius u-image-1"
                  src={Rectangle111}
                  data-image-width="248"
                  data-image-height="98"
                />
                <img
                  className="u-image u-image-contain u-image-default u-preserve-proportions u-image-2"
                  src={Maskgroup}
                  alt=""
                  data-image-width="60"
                  data-image-height="27"
                />
                <p className="u-align-center u-text u-text-1">
                  LG SHOWCASE 2024
                </p>
                <div className="custom-expanded u-form u-form-1">
                  <div
                    className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form"
                    style={{ padding: "10px" }}
                  >
                    <div className="u-form-email u-form-group u-form-group-1">
                      <label
                        htmlFor="email"
                        className="u-custom-font u-label u-text-body-color u-label-1"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        onChange={update}
                        placeholder="Enter your email"
                        id="email"
                        name="email"
                        className="u-border-2 u-border-custom-color-11 u-custom-font u-input u-input-rectangle u-radius u-input-1"
                        required="required"
                      />
                    </div>
                    <div className="u-form-group u-form-group-2">
                      <label
                        htmlFor="password"
                        className="u-custom-font u-label u-text-body-color u-label-2"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        onChange={update}
                        placeholder="********"
                        id="password"
                        name="password"
                        className="u-border-2 u-border-custom-color-11 u-custom-font u-input u-input-rectangle u-radius u-input-2"
                        required
                      />
                    </div>
                    <div className="u-form-checkbox u-form-group u-form-partition-factor-2 u-form-group-3">
                      <input
                        type="checkbox"
                        id="checkbox-b772"
                        name="agree"
                        value="On"
                        className="u-active-custom-color-5 u-agree-checkbox u-border-2 u-border-active-custom-color-5 u-border-grey-75 u-border-hover-custom-color-5 u-field-input"
                      />
                      <label
                        htmlFor="checkbox-b772"
                        className="u-custom-font u-field-label u-text-body-color"
                        style={{
                          fontFamily: "LGEIHeadlineRegular",
                          fontSize: "0.75rem",
                        }}
                      >
                        Remember Me
                      </label>
                    </div>

                    <div className="u-align-center u-form-group u-form-submit u-form-group-5">
                      <button
                        onClick={handleLogin}
                        className="u-active-custom-color-5 u-btn u-btn-round u-btn-submit u-button-style u-custom-color-5 u-custom-font u-hover-custom-color-5 u-radius u-btn-2"
                        data-animation-name=""
                        data-animation-duration="0"
                        data-animation-delay="0"
                        data-animation-direction=""
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
