import React, { useState } from "react";
import Personal from "./Personal";
import Travel from "./Travel";
import MealExcursion from "./MealExcursion";
function Participant({ showPopUp, setShowPopUp }) {
  const [showPersonal, setShowPersonal] = useState(false);
  const [showTravel, setShowTravel] = useState(false);
  const [showMealExcursion, setShowMealExcursion] = useState(false);
  if (showPopUp) {
    return (
      <div className="participant">
        <div className="participant-container"></div>
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <div className="participant-box">
            <div>
              <div className="row px-4 py-3">
                <div className="col-11 p-1 text-center">
                  <h3>Add Participant</h3>
                </div>
                <div className="col-1 p-1">
                  <button
                    className="btn btn-danger"
                    onClick={() => setShowPopUp(false)}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              {/* Tabs */}
              <div className="row px-4 py-2">
                <div className="col p-1">
                  <button
                    className="w-100 btn btn-primary participant-btn participant-btn-active"
                    onClick={() => {
                      setShowPersonal(true);
                      setShowTravel(false);
                      setShowMealExcursion(false);
                    }}
                  >
                    Personal
                  </button>
                </div>
                <div className="col p-1">
                  <button
                    className=" btn w-100  btn-primary participant-btn"
                    onClick={() => {
                      setShowPersonal(false);
                      setShowTravel(true);
                      setShowMealExcursion(false);
                    }}
                  >
                    Travel
                  </button>
                </div>
                <div className="col p-1">
                  <button
                    className="btn btn-primary w-100 participant-btn"
                    onClick={() => {
                      setShowPersonal(false);
                      setShowTravel(false);
                      setShowMealExcursion(true);
                    }}
                  >
                    Meal / Excursion
                  </button>
                </div>
              </div>
              {/* Tabs till here */}

              {/* Form Travel */}
              {showPersonal ? (
                <Personal />
              ) : showTravel ? (
                <Travel />
              ) : showMealExcursion ? (
                <MealExcursion />
              ) : (
                <Personal />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
}

export default Participant;
